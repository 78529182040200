export function useEmbedded() {
  const { companyId } = useStoreApp()

  const { embeddedStoresMap, embeddedCustomerIdMap, embeddedViewportHeight, scrollPosition } =
    storeToRefs(useStoreStore())

  const isEmbedded = computed(() => !!embeddedStoresMap.value.get(companyId))

  const hasCustomerId = computed(() => !!embeddedCustomerIdMap.value.get(companyId))

  return { isEmbedded, hasCustomerId, embeddedViewportHeight, scrollPosition }
}
