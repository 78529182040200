import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  const { public: config } = useRuntimeConfig()

  const { dsn } = config.sentry

  if (!dsn) {
    return
  }

  const ignoreRegexErrors = [
    /\[POST\].*creator\/companies-creators\/from-external-store.*<no response>/,
    /FetchError.\[GET\].*[401|403]/,
    /FetchError.\[GET\].*<no response>/,
    /AbortError/,
    /AbortNavigation/,
    /TypeError.+Importing a module script failed/,
    /TypeError.+Load failed/
  ]

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment: config.env,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    beforeSend(event, hint) {
      const error = hint.originalException as { message?: string }

      if (error?.message) {
        for (const regex of ignoreRegexErrors) {
          if (error.message.match(regex)) {
            return null
          }
        }
      }
      return event
    }
  })
})
