export class ApiErrorClass extends Error {
  override name: string
  statusCode: number
  response: {
    _data: {
      code?: string
      fields_data?: { [key: string]: string[] }
    }
  }

  constructor({ name, statusCode }: { name: string; statusCode: number }) {
    super()
    this.name = name
    this.statusCode = statusCode
    this.response = { _data: {} }
  }
}

export type GenericApiError = {
  detail: string
}

export enum ReferralErrorCode {
  CreatorDoesNotExists = 1,
  TooManyReferralByIp = 2,
  HasEmailVerificationAndEmailNotSend = 3,
  TooManyReferralByDay = 4,
  AlreadyCustomerWithOrder = 5
}

export type ReferralError = {
  error_code: ReferralErrorCode
}

export const StatusMessageBlacklisted = 'blacklisted'
