import type { NuxtError } from '#app'
import { useQuery } from '@tanstack/vue-query'
import type { Company, Order } from '~/types/company'

export const useStoreCompany = defineStore('company', () => {
  const company = ref<Company | null>(null)

  const fetchCompany = async (companyId: number) => {
    try {
      await useNuxtApp().$queryClient.fetchQuery({
        queryKey: ['fetchCompany', companyId],
        queryFn: async () => {
          const data: Company = await useNuxtApp().$api(`/api/creator/companies/${companyId}/`)
          company.value = data
          return data
        }
      })
    } catch (error) {
      const { statusCode } = error as NuxtError
      showError({ statusCode })
    }
  }

  const getOrders = (companyId: number) => {
    return useQuery({
      queryKey: ['fetchOrders', companyId],
      queryFn: async () => {
        return (await useNuxtApp().$api(`/api/creator/company/${companyId}/loyalty/orders/`)) as Order[]
      },
      staleTime: 1800000 // 30 minutes
    })
  }

  return { company, fetchCompany, getOrders }
})
