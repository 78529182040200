import type { RouteParams } from 'vue-router'
import type { Company } from '~/types/company'

export const getUrlPrefix = (routeParams: RouteParams) => {
  const { company, companyId, program } = routeParams
  const prog = program || 'program'
  return `/play/${company}/${companyId}/${prog}`
}

export const getUrlPrefixFromCompany = (company: Company) => {
  const params: RouteParams = {
    company: company.slug,
    companyId: String(company.id),
    program: company.title ?? ''
  }
  return getUrlPrefix(params)
}

export const navigateExternalUrl = (url: string) => {
  try {
    window.parent.location = url
  } catch (e) {
    window.parent.postMessage({ href: url, isWeWiink: true }, '*')
  }
}
